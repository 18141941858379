.WAPContainerActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 25px;
}

.WAPContainerActionsRight>.WAPContainerActionsRightButton {
    padding: 10px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
    background-color: #0A5359;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.WAPContainerActions>.WAPContainerActionsRight {
    display: flex;
    align-items: end;
    gap: 32px;
}

.WAPContainerActionsRightButton:disabled {
    opacity: 0.5;
}

.WAPCard {
    background-color: white;
    border-radius: 10px;
    min-height: 185px;
    padding: 10px;

}

.WAPCardId {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
}

.WAPCardName {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #333;
    font-weight: 600;
    margin-bottom: 5px;
}

.WAPCardLastname {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    color: gray;
    font-weight: 300;
    margin-bottom: 5px;
}

.WAPCardUserId {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #333;
    font-weight: 300;
    margin-bottom: 5px;
}

.WAPCardDate {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #333;
    font-weight: 300;
    margin-bottom: 5px;
    margin-bottom: 25px;
}

.WAPCardAmount {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #000;
    font-weight: 300;
    margin-bottom: 5px;
}