@import url("https://use.typekit.net/sxz6aaz.css");
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "D-DIN";
  src: url("./assets/fonts/D-DIN.woff");
}

@font-face {
  font-family: "D-DIN";
  font-weight: bold;
  src: url("./assets/fonts/D-DIN.woff");
}

@font-face {
  font-family: "Acumin-VC";
  src: url("./assets/fonts/Acumin-Variable-Concept.ttf");
}

* {
  font-family: "massilia";
}

* a {
  cursor: pointer;
  text-decoration: none;
}

.hideMovil{
  display: none;
}

body header {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding-bottom: 15px;
}

body header nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* background-color: #25d366; */
  padding: 1rem 1rem;
  margin-top: 15px;
}

body header nav .links a {
  margin-right: 1rem;
  margin-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  color: #0A5359;
  font-size: 1.3rem;
  text-align: left;
}

body header nav a:hover{
  border-bottom: 3px solid #22AE1D;
  text-decoration: none !important;
}

body header nav .nav-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

body header nav .nav-buttons .nav-btn {
  display: block;
  padding: 0.4rem 1.5rem;
  border-radius: 10px;
  color: white;
  background-color: #0A5359;
  font-weight: bold;
  margin: 0 0.5rem;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  font-size: 1.4rem;
}

body header nav .nav-buttons .nav-btn.reg {
  color: #0A5359;
  background-color: white;
}

body header nav .nav-buttons .nav-btn.reg:hover {
  background-color: #0A5359;
  color: white;
}

body header nav .nav-buttons .nav-btn:hover {
  color: #0A5359;
  background-color: white;
}

body header nav .burguer-btn {
  display: none;
  cursor: pointer;
}

body header nav .burguer-btn div {
  content: "";
  width: 30px;
  border-bottom: 3px solid #22AE1D;
  margin: 5px 0;
}

body header .link-sm-container {
  display: none;
  height: 100vh;
  padding: 2rem;
  background-image: linear-gradient(to bottom, #05D598 , #007F3C);
}

body header .link-sm-container a {
  display: block;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin: 1rem 0;
}

body header .link-sm-container .nav-btn {
  background-color: #0A5359;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
}

body .btn {
  cursor: pointer;
  display: block;
  margin: 3rem auto;
  padding: 1rem 3rem;
  color: white;
  background-color: #0A5359;
  border-radius: 0px 25px 25px 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5rem;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

body .btn:hover {
  color: #0A5359;
  background-color: white;
}

.btn-auth {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

body .landing {
  background-image: url('./components/Landing/Home/assets/fondo-land.png');
  background-size: cover;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

body .landing .floating {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 40%);
          transform: translate(-50%, 40%);
}

body .landing .container {
  max-width: 900px;
}

body .landing .container h1 {
  text-align: center;
  font-size: 5rem;
  color: white;
}

body .second-section {
  padding: 13rem 0;
  background-color: #ebebeb;
}

body .second-section .container {
  max-width: 1200px;
  margin: 0 auto;
}

body .second-section .container .tiendas-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

body .second-section .container .detail {
  font-size: 2.5rem;
  text-align: center;
  margin: 8rem 0;
  color: #091e2d;
}

body .second-section .container .mision-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

body .second-section .container .mision-container div {
  max-width: 300px;
  margin: 1.5rem;
}

body .second-section .container .mision-container div h3 {
  color: #091e2d;
  margin: 2rem 0;
  font-size: 1.8rem;
}

body .second-section .container .mision-container div p {
  font-size: 1.1rem;
  color: gray;
}

body .third-section {
  padding: 7rem 2rem;
  background-color: #091e2d;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1000px 350px;
      grid-template-columns: 1000px 350px;
  margin: 0 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

body .third-section .title {
  position: relative;
}

body .third-section .title h2 {
  text-align: center;
  color: white;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}

body .third-section .title p {
  font-size: 2rem;
  color: #ebebeb;
  max-width: 600px;
  margin: auto;
  margin-bottom: 2rem;
}

body .third-section .title .floating {
  position: absolute;
  bottom: 150px;
  left: 0;
  -webkit-transform: translateY(70%);
          transform: translateY(70%);
}

body .third-section .list ul li {
  margin: 5rem 0;
}

body .third-section .list ul li h4 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

body .third-section .list ul li p {
  color: #ebebeb;
  font-size: 1.5rem;
}

body .fourth-section {
  background: url("./assets/image/background-1.jpg");
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 950px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 20rem;
}

body .fourth-section .text {
  max-width: 550px;
  margin-right: 10rem;
  color: white;
}

body .fourth-section .text h2 {
  font-size: 5rem;
  margin-bottom: 2rem;
}

body .fourth-section .text p {
  font-size: 2rem;
}

body .fourth-section .text .store-container {
  margin: 2rem 0;
}

body .fourth-section .image-container {
  position: relative;
}

body .fourth-section .image-container .floating {
  position: absolute;
  bottom: -75px;
  left: 0;
  -webkit-transform: translateY(40%);
          transform: translateY(40%);
}

body .winner-section {
  background: url("./assets/image/fondo-ganador.png");
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5rem 2rem;
}

body .winner-section .text {
  max-width: 550px;
  margin: 2rem 3rem 0;
}

body .winner-section .text h2 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: normal;
}

body .winner-section .text p {
  margin: 1rem 0;
  font-size: 1.2rem;
  color: gray;
}

body .contactanos-section {
  background: url("./assets/image/background-2.jpg");
  background-position: center;
  background-size: cover;
  height: 40vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  text-align: center;
}

body .contactanos-section div h3 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

body .contactanos-section div p {
  max-width: 900px;
  font-size: 1.8rem;
}

body .contactanos-section div .btn:hover {
  color: #091e2d;
  background-color: white;
  border: 2px solid #091e2d;
}

body footer {
  padding-top: 10rem;
  padding-bottom: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

body footer .container .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 1200px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

body footer .container .links div h4 {
  color: #091e2d;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

body footer .container .links div ul li a {
  display: block;
  margin: 1rem 0;
  color: #091e2d;
  cursor: pointer;
}

body footer .container .last p {
  text-align: center;
  margin-top: 10rem;
  padding-top: 3rem;
  border-top: 3px solid #ebebeb;
  color: #091e2d;
}

@media screen and (max-width: 900px) {
  body header nav {
    padding: 1rem 2rem;
  }
  body header nav .links,
  body header nav .nav-buttons {
    display: none;
  }
  body header nav .burguer-btn {
    display: block;
  }
  body .landing .container h1 {
    font-size: 2.5rem;
  }
  body .landing .floating {
    width: 100%;
  }
  body .btn {
    font-size: 1rem;
  }
  body .second-section {
    padding: 15rem 2rem;
  }
  body .third-section {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  body .third-section .title h2 {
    font-size: 2rem;
  }
  body .third-section .title p {
    font-size: 1.5rem;
    text-align: center;
  }
  body .third-section .title .floating {
    position: static;
    width: 100%;
    -webkit-transform: none;
            transform: none;
  }
  body .third-section .list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  body .third-section .list ul li {
    max-width: 300px;
    margin: 1.5rem;
  }
  body .fourth-section {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: auto;
    margin-top: 0;
    padding: 2rem;
  }
  body .fourth-section .text {
    margin-right: 0;
  }
  body .fourth-section .text h2 {
    font-size: 3rem;
  }
  body .fourth-section .image-container .floating {
    position: static;
    -webkit-transform: none;
            transform: none;
    width: 100%;
  }
  body .contactanos-section {
    padding: 2rem;
  }
  body .contactanos-section div h3 {
    font-size: 2rem;
  }
  body .contactanos-section div p {
    font-size: 1.5rem;
  }
  body footer {
    padding: 2rem;
  }
  body footer .container {
    width: 100%;
  }
  body footer .container .links {
    width: 100%;
  }
  body footer .container .links div {
    padding: 2rem;
  }
  body footer .container .last p {
    margin: 0;
  }
  #imageSlideShow {
    order: -1;
  }
  .serviceContent{
    padding: unset !important;
    justify-content: center !important;
  }
  .serviceTitle{
    font-size: 22px;
  }
  .storeLinks{
    flex-direction: column-reverse;
    order: -1;
  }
  .downloadImage{
    order: -2;
  }
  .hideWeb {
    display: none;
  }
  .showmovil {
    display: flex !important;
  }
  .paddingmovil {
    padding-top: 100px !important;
  }
  .sizemovil {
    width: 50%;
  }
}

@media screen and (max-width: 500px) {
  body header nav .logo {
    width: 50%;
  }
  body .second-section {
    padding: 7rem 2rem;
  }
  body .second-section .container .tiendas-container {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  body .second-section .container .detail {
    font-size: 1.5rem;
    margin: 6rem 0;
  }
  body .second-section .container .mision-container div img {
    display: block;
    margin: 0 auto;
  }
  body .fourth-section {
    padding: 5rem 2rem;
  }
  body .fourth-section .image-container {
    display: none;
  }
  body .winner-section img {
    width: 100%;
  }

  #navbar-main-collapse > ul.navbar-nav.align-items-lg-center > a.active {
    background: #0A1F2E;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: 0px 3px 6px #0A1F2E80;
  }

  .navbar-expand-lg .navbar-nav .nav-item {
    background: #0A1F2E;
    border-radius: 10px;
    padding: 0px 10px;
    box-shadow: 0px 3px 6px #0A1F2E80;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: .35rem;
    padding-bottom: .35rem;
    border-radius: 0;
  }
}
/*# sourceMappingURL=global.css.map */
